









































































































  import {Component, Vue} from 'vue-property-decorator';
  import CusTable from "@/components/table";
  import MixinEdu from "@/views/education/Mixins/mixin-edu";
  import double2input from "@/views/education/util/double2input";
  @Component({
    name: 'Version',
    components:{CusTable},
    mixins: [ MixinEdu ],
	  directives: { double2input }
  })
  export default class Version extends Vue {
    constructor(prop) {
      super(prop);
    };
    private loading = false
    private tableHead:Array<{[key:string]: any}> = [
      {
        property: 'modifierName',
        label: '修改人',
        width: 'auto',
        align: 'left',
	      isShow: false
      },

      {
        property: 'modifyTime',
        label: '修改时间',
        width: 'auto',
        align: 'left',
        isShow: true
      },
      {
        property: 'status',
        label: '状态',
        width: 'auto',
        align: 'left',
        isShow: true,
        render: (row:any, column:any, cellValue:any, index:number) => {
          return row.status == 0 ? '启用' : '未启用'
        }
      }
    ];

    private AddDialogVisible = false;
    private inputVal = '';
    private rowId = '';
    private activeNames = [];
    private activeNames_length = 0;
    private isDrawer = false;
    private detail:any = {};
    private subjectArr = [];
    private loading = false;
    private inputVisible = false;
    private textInputVal =  ''

    /**
     * 批量停用/启用
     */
    private batchChangeStatusHandle(status) {
      this.batchChangeStatus(status, this.$refs.table);
    };

    /**
     * 停用 && 启用
     */
    private handleChangeStatus(idx, row: never | any) {
      this.selectedArr = [row];
      this.batchChangeStatus((row.status == 1 ? 0 : 1), this.$refs.table)
    }

    private handleDelete(idx, row: never | any) {
      this.selectedArr = [row];
      this.batchDeleteHandle(this.$refs.table);
    }

    private selectionChange(ev) {
      this.selectedArr = ev;
    };

    private async addGrade() {
      let data = {
        id: this.rowId,
        typeCode: (this.$route as any).meta.type,
        name: this.inputVal,
      }
      const res: any = await this.axios.post('/system/dictionary/saveOrUpdate', data);
      if (res.result) {
        this.cusTableKey = Date.now();
        this.AddDialogVisible = !this.AddDialogVisible;
      }
    };

    private async openDetail(detail) {
      this.loading = true;
      this.detail = detail;
      const res: any = await this.axios.post('/admin/courseSystem/query', {relationType: 'TEACH_BOOK_VERSION', relationValue: detail.id});
      setTimeout(() => {
        this.loading = false;
        if (res.result) this.subjectArr = res.json;
      }, 1000)
      if (this.isDrawer) return;
      if (!this.isDrawer) this.isDrawer = true;

    }

    private handleEdit(idx, row) {
      this.AddDialogVisible = true;
      this.rowId = row.id;
      this.inputVal = row.name;
    };

    private handleChange() {
      // 打开面板
      if (this.activeNames_length < this.activeNames.length) {
        this.getTextData(this.activeNames[this.activeNames.length - 1]);
      }
			this.activeNames_length = this.activeNames.length;
    };

    private async deleteText(id, subject) {
      const res: any = await this.axios.post('/tiku/bookVersion/deleteById', {id});
      if (res.result) {
        this.$message({
	        type: 'success',
	        message: '删除成功'
        });
        this.getTextData(subject);
      }
    };

    private getTextData(subject) {
      this.subjectArr.forEach((item: any) => {
        if (item.subjectId === subject) this.$set(item, 'loading', true)
      });
      setTimeout(() => {
        this.axios.post('/tiku/bookVersion/queryAll', {versionId: this.detail.id, subject}).then((res: any) => {
          this.subjectArr.forEach((item: any) => {
            if (item.subjectId === this.activeNames[this.activeNames.length - 1]) {
              this.$set(item, 'child', res.json);
              this.$nextTick(() => {
                this.$set(item, 'loading', false)
              })
            }
          });
        })
      }, 1000)
    };

    private showInput(subject) {
      this.inputVisible = true;
      this.$nextTick(() => {
        (this.$refs.saveInput as any).$refs.input.focus();
      });
    };

    private async blurText(subject) {
      if (this.textInputVal == '') return ;
      this.subjectArr.forEach((item: any) => {
        if (item.subjectId === subject) this.$set(item, 'loading', true)
      });
      const res: any = await this.axios.post('/tiku/bookVersion/addBookVersion', {name: this.textInputVal, subject, versionId: this.detail.id, versionName: this.detail.name}, {headers:{'Content-Type': 'application/json'}});
      if (res.result) {
        this.getTextData(subject);
        this.inputVisible = false;
        this.textInputVal = '';
      }
    };

    private top(bookVersionId, subject) {
			this.axios.post('/tiku/bookVersion/moveUpOrDownBookVersion', {bookVersionId, moveType: 1}).then((res: any) => {
			  this.getTextData(subject);
			})
    };

    private bottom(bookVersionId, subject) {
      this.axios.post('/tiku/bookVersion/moveUpOrDownBookVersion', {bookVersionId, moveType: -1}).then((res: any) => {
        this.getTextData(subject);
      })
    };

	  private __HandleClick(Visible) {
      this.isDrawer = Visible
	  }
  }
