export default {
  bind: function (el, binding, vnode) {
    el.ondblclick = function (e) {
      var input = document.createElement('input');
      input.classList.add('input-box');
      el.parentNode.insertBefore(input, el);
      input.addEventListener('blur', function (e:any) {
        if (e.target.value !== '') {
          vnode.context.$emit('sendVal', {name: e.target.value, subject: binding.value})
        };
        this.remove();
      });
    };
  },
  inserted: function (el, binding, vnode) {

  }
}
